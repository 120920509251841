import { loadMulpay } from '@mul-pay/mptoken-js';
import { setValid, setInvalid } from './validations';
import { $1, EventOf, on, registerStartup } from 'lib/utils';

const cardBrands = [
  [/^35/, 'cc-jcb', 16, 16, 3],
  [/^3[47]/, 'cc-amex', 15, 15, 4],
  [/^3[0689]/, 'cc-diners-club', 14, 16, 3],
  [/^4/, 'cc-visa', 16, 16, 3],
  [/^(?:2[2-7]|5[1-5])/, 'cc-mastercard', 16, 16, 3],
] as const;

const PLEASE_WAIT = '恐れ入りますが、しばらく待ってから再度送信してください。';
const tokenErrors: Record<number, string> = {
  100: 'カード番号が正しく入力されていません。',
  110: '有効期限が正しく入力されていません。',
  120: 'セキュリティコードが正しく入力されていません。',
  130: 'カード名義が正しく入力されていません。',
  900: '決済サーバーが混み合っています。' + PLEASE_WAIT,
};

registerStartup(() => {
  on('submit', 'form', handleSubmit);
  on(['input', 'focusout'], 'input[autocomplete="cc-number"]', checkCardNumber);
  on(['input', 'focusout'], 'input[autocomplete="cc-exp"]', checkCardExp);
  on('submit', '.receipt-download', checkReceiptAddress);
});

async function handleSubmit(ev: EventOf<HTMLFormElement>) {
  const form = ev.currentTarget;
  if (!(form.checkValidity() && form.querySelector('input[autocomplete="cc-number"]:enabled'))) return;

  ev.preventDefault();
  const submits = form.querySelectorAll<HTMLButtonElement>('input[type=submit],button[type=submit]');
  submits.forEach((e) => (e.disabled = true));
  const ccElems = Object.fromEntries(
    Array.from(form.querySelectorAll<HTMLInputElement>('[autocomplete^="cc-"]')).map((elem) => [
      elem.getAttribute('autocomplete')!,
      elem,
    ]),
  );

  try {
    const data = await queryToken(ccElems);
    Object.entries(data).forEach(([key, value]) => {
      const elem = form.querySelector<HTMLInputElement>(`[data-${key}]`);
      if (elem) elem.value = value;
    });
    Object.entries(ccElems).forEach(([key, elem]) => {
      elem.required = false;
      elem.maxLength = elem.minLength = 0;
      elem.pattern = '';
      elem.readOnly = true;
      elem.value = data[key] || '****';
    });
    form.submit();
  } catch (err) {
    alert(err);
    submits.forEach((e) => (e.disabled = false));
  }
}

function checkCardNumber(ev: EventOf<HTMLInputElement>) {
  const elem = ev.currentTarget;
  const value = elem.value;
  const iconElem = $1(elem.dataset.cardBrand!)!;
  iconElem.className = 'fas fa-credit-card';

  if (value === '' || /\D/.test(value)) {
    setInvalid(elem, 'クレジットカード番号を数字で入力してください');
    return;
  }
  for (const [prefix, icon, minLength, maxLength, cscLength] of cardBrands) {
    if (prefix.test(value)) {
      iconElem.className = `fab fa-${icon}`;
      elem.maxLength = elem.minLength = 0;
      elem.maxLength = maxLength;
      elem.minLength = minLength;
      elem.pattern = `\\d{${minLength},${maxLength}}`;
      const cscElem = elem.form!.querySelector<HTMLInputElement>(`[autocomplete="cc-csc"]`);
      if (cscElem) {
        cscElem.maxLength = cscElem.minLength = 0;
        cscElem.minLength = cscElem.maxLength = cscLength;
        cscElem.pattern = `\\d{${cscLength}}`;
      }
      break;
    }
  }
  if (value.length >= elem.minLength && value.length <= elem.maxLength && luhn(value) === 0) {
    setValid(elem);
  } else {
    setInvalid(elem, 'クレジットカード番号を正しく入力してください。');
  }
}

function luhn(value: string) {
  const sum = value
    .split('')
    .reverse()
    .reduce((sum, n, index) => {
      const n2 = index % 2 == 1 ? +n * 2 : +n;
      return sum + (n2 <= 9 ? n2 : (n2 % 10) + Math.floor(n2 / 10));
    }, 0);
  return sum % 10;
}

function checkCardExp(ev: EventOf<HTMLInputElement, InputEvent>) {
  const elem = ev.currentTarget;
  const value = elem.value;
  if (/^\d{2,}$/.test(value) && (ev.type === 'focusout' || ev.data)) {
    elem.value = `${value.slice(0, 2)}/${value.slice(2)}`;
    return;
  }
  const match = /^(0\d|1[012])\/(\d\d)$/.exec(value);
  if (match) {
    const now = new Date();
    const nowYM = `${now.getFullYear() - 2000}${`00${now.getMonth() - 1}`.slice(-2)}`;
    const valueYM = `${match[2]}${match[1]}`;
    console.log(`checkCardNumber: ${valueYM} >= ${nowYM}`);
    if (valueYM >= nowYM) {
      setValid(elem);
      return;
    }
  }
  setInvalid(elem, '有効期限を 月/年 の形式で入力してください。');
}

async function queryToken(elems: Record<string, HTMLInputElement | undefined>): Promise<Record<string, string>> {
  const args = await getTokenApiKey();
  const mulpay = await loadMulpay(...args).catch((err) => console.error(err));
  if (!mulpay) {
    throw new Error('トークンAPIの初期化ができませんでした。' + PLEASE_WAIT);
  }

  const req = {
    cardno: elems['cc-number']?.value ?? '?',
    expire: elems['cc-exp']?.value.replace(/^(\d\d)\/(\d\d)$/, '$2$1') ?? '?',
    securitycode: elems['cc-csc']?.value ?? '?',
    holdername: elems['cc-name']?.value ?? '?',
  };
  const res = await mulpay.getToken(req);
  if (!(res && res.resultCode == 0 && res.tokenObject)) {
    const resultCode = res?.resultCode ?? 0;
    const errorMessage = tokenErrors[resultCode - (resultCode % 10)] ?? '内部エラーです。';
    throw new Error(`クレジットカード番号のトークンが取得できませんでした。${errorMessage} (ERR${resultCode})`);
  }

  return {
    'cc-token': res.tokenObject.token as string,
    'cc-number': res.tokenObject.maskedCardNo,
    'cc-token-expires': res.tokenObject.toBeExpiredAt,
  };
}

async function getTokenApiKey(): Promise<Parameters<typeof loadMulpay>> {
  const res = await fetch('/ajax/token_api_key');
  const data = await res.json();
  return data.initArgs;
}

function checkReceiptAddress(ev: EventOf<HTMLFormElement>) {
  const elements = ev.currentTarget.querySelectorAll<HTMLInputElement>('input');
  if ([...elements].every((el) => !el.checked)) {
    if (!confirm('領収書の宛名が空欄となりますがよろしいですか。')) ev.preventDefault();
  }
}
